import moment from "moment";

export const formatReportedDate = (dateString) => {
  if (dateString !== " ") {
    return new Date(dateString).toLocaleDateString("en-GB", {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
    });
  } else {
    return " ";
  }
};

export const format_date = (ts) => moment(ts).format("DD/MM/YYYY");

const DTF = "Do /MMM /YYYY"; // Define the date format string

export const format_dt_ago = (ts) => {
  const m = moment(ts);
  return `${m.format(DTF)} (${m.fromNow()})`;
};

export const format_dt_age = (dob) => {
  const dobMoment = moment(dob); // Use moment to parse the date of birth
  const currentMoment = moment(); // Get the current date using moment

  // Calculate the age
  const age = currentMoment.diff(dobMoment, "years");

  // Format the date of birth and return the result
  return `${dobMoment.format("DD/MM/YYYY")} (${age} years old)`;
};

export const formatDob = (dateString) => {
  const [month, day, year] = dateString.split("/");

  // Check if the input date is valid
  if (!month || !day || !year) {
    throw new Error("Invalid date format. Must be in MM/DD/YYYY format.");
  }

  // Convert to YYYY-MM-DD format
  return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
};

/**
 * Format data by trimming strings and converting dob to yyyy-dd-mm format.
 * @param {Object} data - The data object to format.
 * @returns {Object} - The formatted data object.
 */
const formatData = (data) => {
  // Create a new object with trimmed values
  const trimmedData = Object.keys(data).reduce((acc, key) => {
    acc[key] = typeof data[key] === "string" ? data[key].trim() : data[key];
    return acc;
  }, {});

  // Return the trimmed data object without modifying dob
  return trimmedData;
};

export default formatData;

export const formatBarcode = (barcode) => {
  let cleanedBarcode = barcode.replace(/[^a-zA-Z0-9]/g, ""); // Remove non-alphanumeric characters

  let firstSegment = cleanedBarcode
    .slice(0, 3)
    .toUpperCase()
    .replace(/[^A-Z]/g, ""); // Capitalize and remove non-alphabets
  let secondSegment = cleanedBarcode
    .slice(3, 6)
    .replace(/[^a-zA-Z]/g, "")
    .toLowerCase(); // Remove non-alphabets
  let remainingSegment = cleanedBarcode.slice(6, 10);

  if (remainingSegment) {
    return `${firstSegment}-${secondSegment}-${remainingSegment}`;
  } else if (secondSegment) {
    return `${firstSegment}-${secondSegment}`;
  } else {
    return firstSegment;
  }
};

export const statusMapping = {
  registered: { variant: "primary", label: "Registered" },
  created: { variant: "primary", label: "Registered" },
  processing: { variant: "yellow", label: "Processing" },
  in_transit: { variant: "powder-pink", label: "In Transit" },
  processing_consultation: { variant: "mint", label: "Processing" },
  processing_mint: { variant: "mint", label: "Processing" },
  processing_raspberry: { variant: "raspberry", label: "Processing" },
  results_ready: { variant: "dark-green", label: "Results Ready" },
  activating: { variant: "grey-lilac", label: "Activating" },
  rejected: { variant: "raspberry", label: "Rejected" },
};

// Define a function to handle the asynchronous update of meet_details
export const TimeFormatter = (date, time) => {
  return new Promise((resolve) => {
    if (!date || !time) {
      resolve("");
      return;
    }

    // Combine date and time into a single string
    const combinedDateTimeStr = `${date}T${time}`;

    // Parse the combined string into a Date object
    const dateObj = new Date(combinedDateTimeStr);

    // Check if the dateObj is valid
    if (isNaN(dateObj.getTime())) {
      resolve("");
      return;
    }

    // Extract components
    const year = dateObj.getUTCFullYear();
    const month = String(dateObj.getUTCMonth() + 1).padStart(2, "0");
    const day = String(dateObj.getUTCDate()).padStart(2, "0");
    const hours = String(dateObj.getUTCHours()).padStart(2, "0");
    const minutes = String(dateObj.getUTCMinutes()).padStart(2, "0");
    const seconds = String(dateObj.getUTCSeconds()).padStart(2, "0");
    const milliseconds = String(dateObj.getUTCMilliseconds()).padStart(3, "0");

    // Get timezone offset
    const offset = -dateObj.getTimezoneOffset();
    const offsetHours = String(Math.floor(Math.abs(offset) / 60)).padStart(
      2,
      "0",
    );
    const offsetMinutes = String(Math.abs(offset) % 60).padStart(2, "0");
    const sign = offset >= 0 ? "+" : "-";

    // Construct the formatted datetime string
    const formattedDateTime = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}.${milliseconds}${sign}${offsetHours}:${offsetMinutes}`;

    //resolve the promise
    resolve(formattedDateTime);
  });
};

/**
 * Returns testtype in a formatted manner
 */
export const formatTestType = (testType) => {
  // Mapping of test types to their corresponding formats
  const testTypeMapping = {
    "Cholesterol Profile": "blood-cholesterol",
    "Energy Profile": "blood-energy",
    "Female Sexual Health": "blood-female-sex-health",
    "General Health": "blood-general",
    "Heart Profile": "blood-heart",
    "Male Sexual Health": "blood-male-sex-health",
    "Menopause Profile": "blood-menopause",
    "Polycystic Ovary Syndrome (PCOS)": "blood-polycystic",
    "Vitamins & Minerals Profile": "blood-vitamins",
    "Weight Management": "blood-weight",
    "Thyroid Profile": "blood-thyroid",
    "Erectile Dysfunction": "blood-erectile",
  };

  // Return the formatted test type if it exists in the mapping
  return testTypeMapping[testType] || "Unknown test type";
};
