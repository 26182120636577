import React, { useEffect } from "react";
import useApis from "../services/useAPI";
import { Spinner } from "react-bootstrap";
import { enableMfaFields, links } from "../stories/Forms/fields";
import DynamicForm from "../stories/Forms/DynamicForm";
import { useAuth } from "../components/AuthContext/AuthContext";
import { enableMfaSchema } from "../stories/Forms/validationSchema";
import { useNavigate } from "react-router-dom";
import Toaster from "../stories/Toasts/Toaster";
import { enableMFAUrl, phlebotomistVerifyOtpUrl } from "../utils/constants";
import LoggedInLayout from "../stories/LoggedInScreens/LoggedInLayout";
import logo from "../assets/images/mhc.png";
import img from "../assets/images/notification.svg";

// Main component for enabling Multi-Factor Authentication (MFA)
const EnableMFA = () => {
  // Custom hook for API calls and state management
  const {
    callApi,
    resultPost,
    serverError,
    tokenError,
    resultGet,
    message,
    showSuccess,
    isLoading,
    toggleShowError,
    toggleShowSuccess,
  } = useApis(enableMFAUrl, "GET");

  const navigate = useNavigate();
  const auth = useAuth();

  // To get QR code for user
  useEffect(() => {
    auth.setTitle("Enable MFA");

    callApi("", enableMFAUrl, "GET");
  }, [auth.title]);

  // Handle form submission and OTP verification
  const handleSubmit = async (data) => {
    const postData = {
      ...data,
      user_id: auth.user,
    };

    await callApi(postData, phlebotomistVerifyOtpUrl, "POST", {
      Authorization: `Bearer ${auth.accessToken}`,
      "Content-Type": "application/json",
    });
  };

  // Manage post-submission success and redirection
  useEffect(() => {
    if (message) {
      localStorage.setItem("isLoggedIn", true);
      auth.login(resultPost);
      localStorage.setItem("access_token", resultPost.access_token); // Store access token
      localStorage.setItem("refresh_token", resultPost.refresh_token); // Store refresh token
      localStorage.setItem("mfa_enabled", resultPost.mfa_enabled);
      setTimeout(() => {
        navigate("/patient-record");
      }, 1000);
    }
  }, [resultPost]);

  return (
    <LoggedInLayout
      buttonLabel={"Logout"}
      img={img}
      isActivation={false}
      links={links}
      logo={logo}
      pageTitle={auth.title}
      userInitials={auth.userInitials}
    >
      <div className="row justify-content-center pt-4">
        <div className="col-md-6">
          <h2>Enable App Multi-factor Authentication (MFA)</h2>
          <div className="text-muted">
            <p>
              Multi-factor Authentication (also known as Two Factor
              authentication or 2FA) improves your account security by requiring
              a second means of authentication when logging in.
            </p>
            <p>Scan the QR code below using one of these app:</p>
            <ul>
              <li>
                <b>Google Authenticator</b> for{" "}
                <Link app="ga" platform="Android" /> or{" "}
                <Link app="ga" platform="iPhone" />
              </li>
              <li>
                <b>Authy</b> for <Link app="authy" platform="Android" /> or{" "}
                <Link app="authy" platform="iPhone" />
              </li>
            </ul>
          </div>
          <div className="d-flex justify-content-center pt-2">
            {isLoading ? (
              <Spinner />
            ) : (
              <img
                alt="MFA QR code"
                className="rounded qr-image border"
                height="300"
                src={`data:image/png;base64,${resultGet?.qr_code_image}`}
                width="300"
              />
            )}
          </div>
          <DynamicForm
            fields={enableMfaFields}
            onSubmit={handleSubmit}
            serverError={serverError}
            validationSchema={enableMfaSchema}
          />
          {resultPost && (
            <Toaster
              aria-roledescription="alert"
              body="MFA enabled successfully!"
              header="Success"
              onClose={toggleShowSuccess}
              position="top-end"
              role="alert"
              show={showSuccess}
            />
          )}
        </div>
      </div>
      {tokenError && (
        <Toaster
          body={tokenError}
          header="Error"
          onClose={toggleShowError}
          position="top-end"
          show={tokenError}
        />
      )}
    </LoggedInLayout>
  );
};

export default EnableMFA;

// Link component for app download links
const Link = ({ platform, app }) => (
  <a href={link[platform][app]} rel="noopener noreferrer" target="_blank">
    {platform}
  </a>
);

// Links for different MFA apps
const link = {
  Android: {
    ga: "https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2&hl=en_GB&gl=US",
    authy:
      "https://play.google.com/store/apps/details?id=com.authy.authy&hl=en_GB&gl=US",
  },
  iPhone: {
    ga: "https://apps.apple.com/us/app/google-authenticator/id388497605",
    authy: "https://apps.apple.com/us/app/twilio-authy/id494168017",
  },
};
