import React from "react";
import Form from "react-bootstrap/Form";
import FormInput from "./FormInput";
import { Button } from "../Buttons/Button";
import { Formik } from "formik";
import PromptIfDirty from "./PromptIfDirty";
import PropTypes from "prop-types";
import { loginSchema } from "./validationSchema";
import { InfoCircleFill } from "react-bootstrap-icons";

/**
 * A Form structured such that it renders,
 * each field passed to it one after the other
 * @param {*} props
 * @returns
 */
const DynamicForm = (props) => {
  // Create initial values array based on fields passed in props
  const generateInitialValues = (fields, data) => {
    const initialValues = {};
    fields.forEach((field) => {
      if (field.type !== "button" && field.type !== "submit") {
        initialValues[field.name] =
          data?.[field.name] !== undefined ? data[field.name] : "";
      }
    });
    return initialValues;
  };

  const initialValues = generateInitialValues(props.fields, props.data);

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      onSubmit={(values, { resetForm }) => {
        delete values.undefined;
        props.onSubmit?.(values);
        resetForm();
      }}
      validationSchema={props.validationSchema}
    >
      {({ handleSubmit, handleChange, values, touched, errors }) => {
        // Call onErrorChange to update parent with current errors and touched states
        if (props.onErrorChange) {
          props.onErrorChange(errors, touched);
        }

        return (
          <Form
            className="text-left form-wrapper"
            noValidate
            onSubmit={handleSubmit}
          >
            {props.askConfirmation && <PromptIfDirty />}

            {props.fields.map((field) => (
              <Form.Group key={field.controlId}>
                {field.type === "button" || field.type === "submit" ? (
                  <div className="form-group">
                    {props.serverError && (
                      <div className="server-error">
                        <InfoCircleFill /> {props.serverError}
                      </div>
                    )}
                    <Button
                      className={field.className}
                      href={field?.href}
                      label={field.label}
                      size={field.size}
                      type={field.type}
                      variant={field.variant}
                    />
                  </div>
                ) : (
                  <FormInput
                    className="inputs"
                    error={errors[field.name]}
                    key={field.controlId}
                    onChange={(e) => {
                      handleChange(e);
                      if (props.onChange) {
                        props.onChange(e.target.name, e.target.value);
                      }
                    }}
                    touched={touched[field.name]}
                    type={field.type}
                    value={values[field.name]}
                    {...field}
                  />
                )}
              </Form.Group>
            ))}
          </Form>
        );
      }}
    </Formik>
  );
};

DynamicForm.defaultProps = {
  fields: [],
  validationSchema: loginSchema,
  onSubmit: undefined,
  askConfirmation: false, // Ensure default prop for askConfirmation
};

DynamicForm.propTypes = {
  validationSchema: PropTypes.objectOf(PropTypes.any),
  fields: PropTypes.arrayOf(PropTypes.object),
  onSubmit: PropTypes.func,
  onChange: PropTypes.func,
  data: PropTypes.object,
  askConfirmation: PropTypes.bool, // PropType for askConfirmation
  onErrorChange: PropTypes.func, // Corrected PropType for onErrorChange
};

export default DynamicForm;
