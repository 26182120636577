import React from "react";
import BootstrapTable from "react-bootstrap-table-next";
import cellEditFactory from "react-bootstrap-table2-editor";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import "react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css";
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";
import { Container, Col, Row } from "react-bootstrap";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";

// Function to format barcode
export const formatBarcode = (barcode) => {
  let cleanedBarcode = barcode.replace(/[^a-zA-Z0-9]/g, ""); // Remove non-alphanumeric characters

  let firstSegment = cleanedBarcode
    .slice(0, 3)
    .toUpperCase()
    .replace(/[^A-Z]/g, ""); // Capitalize and remove non-alphabets
  let secondSegment = cleanedBarcode
    .slice(3, 6)
    .replace(/[^a-zA-Z]/g, "")
    .toLowerCase(); // Remove non-alphabets
  let remainingSegment = cleanedBarcode.slice(6, 10);

  if (remainingSegment) {
    return `${firstSegment}-${secondSegment}-${remainingSegment}`;
  } else if (secondSegment) {
    return `${firstSegment}-${secondSegment}`;
  } else {
    return firstSegment;
  }
};

const SimpleTable = ({ data = {}, editable = false, onUpdate, rejectPath }) => {
  const transformData = (data) => {
    if (!data) return [];

    const fieldMap = {
      full_name: "Patient name",
      dob: "D.O.B",
      test_type: "Test type",
      primary_barcode: "SST barcode",
      secondary_barcode: "EDTA barcode",
      sample_collection_date: "Date",
      sample_collection_time: "Time",
      number_of_attempts: "No. of attempts",
      store_location: "Store location",
    };

    return Object.entries(data)
      .filter(([key, value]) => {
        if (key === "secondary_barcode") {
          return !!value;
        }
        return fieldMap.hasOwnProperty(key);
      })
      .map(([key, value]) => ({
        id: key,
        header: fieldMap[key],
        value: value || "",
        editable: isEditableField(key),
      }));
  };

  const isEditableField = (fieldName) => {
    const nonEditableFields = [
      "full_name",
      "dob",
      "test_type",
      "store_location",
      "secondary_barcode",
    ];
    return !nonEditableFields.includes(fieldName);
  };

  const transformedData = transformData(data);

  const customEditor = (onUpdate, props) => (
    <DatePicker
      dateFormat="yyyy-MM-dd"
      onChange={
        (date) => onUpdate(date ? moment(date).format("YYYY-MM-DD") : "") // Convert Date object to formatted string
      }
      selected={props.value ? moment(props.value, "YYYY-MM-DD").toDate() : null} // Convert date string to Date object
    />
  );

  const timeEditor = (onUpdate, props) => (
    <input
      defaultValue={props.value}
      onBlur={(e) => onUpdate(e.target.value)}
      type="time"
    />
  );

  const attemptsEditor = (onUpdate, props) => (
    <select
      defaultValue={props.value}
      onChange={(e) => onUpdate(parseInt(e.target.value, 10))}
    >
      {[1, 2].map((num) => (
        <option key={num} value={num}>
          {num}
        </option>
      ))}
    </select>
  );

  const defaultEditor = (onUpdate, props) => (
    <input
      defaultValue={props.value}
      onBlur={(e) => onUpdate(e.target.value)}
      type="text"
    />
  );

  const barcodeEditor = (onUpdate, props) => (
    <input
      defaultValue={props.value}
      onBlur={(e) => onUpdate(formatBarcode(e.target.value))}
      type="text"
    />
  );

  const columns = [
    {
      dataField: "header",
      text: "Field",
      editable: false,
      headerClasses: "d-none",
      classes: "font-weight-bold",
    },
    {
      dataField: "value",
      text: "Value",
      headerClasses: "d-none",
      editorRenderer: (editorProps, value, row) => {
        if (row.id === "sample_collection_date") {
          return customEditor(editorProps.onUpdate, { value });
        } else if (row.id === "sample_collection_time") {
          return timeEditor(editorProps.onUpdate, { value });
        } else if (row.id === "number_of_attempts") {
          return attemptsEditor(editorProps.onUpdate, { value });
        } else if (row.id === "secondary_barcode") {
          return barcodeEditor(editorProps.onUpdate, { value });
        }
        return defaultEditor(editorProps.onUpdate, { value });
      },
      editable: (cell, row) => row.editable && editable,
      classes: (cell, row) =>
        !rejectPath ? (row.editable ? "" : "non-editable") : "",
    },
  ];

  return (
    <Container className="p-0">
      <Row>
        <Col xl={7}>
          <div className="appointment-confirmation-table">
            <BootstrapTable
              cellEdit={
                editable &&
                cellEditFactory({
                  afterSaveCell: (oldValue, newValue, row, column) => {
                    if (onUpdate) {
                      onUpdate(row.id, newValue);
                    }
                  },
                  blurToSave: true,
                  mode: "click",
                })
              }
              columns={columns}
              data={transformedData}
              keyField="id"
            />
          </div>
        </Col>
        <Col xl={5}></Col>
      </Row>
    </Container>
  );
};

export default SimpleTable;
