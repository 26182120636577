import { React, useEffect } from "react";
import DynamicForm from "../stories/Forms/DynamicForm";
import { useNavigate, useParams } from "react-router-dom";
import { resetPasswordFields } from "../stories/Forms/fields";
import { resetPasswordSchema } from "../stories/Forms/validationSchema";
import Toaster from "../stories/Toasts/Toaster";
import Invalid from "../stories/utils/Invalid";
import useApis from "../services/useAPI";
import { useAuth } from "../components/AuthContext/AuthContext";
import { Button } from "../stories/Buttons/Button";
import { logoutUrl, resetPasswordUrl } from "../utils/constants";
import logo from "../assets/images/mhc.png";
import LoggedOutLayout from "../stories/LoggedOutScreens/LoggedOutLayout";

function ResetPassword() {
  const { id, uuid } = useParams();
  const url = `${resetPasswordUrl}/${uuid}/${id}/`;

  const navigate = useNavigate();
  const auth = useAuth();

  useEffect(() => {
    auth.setTitle("Reset password");
  }, [auth.title]);

  const { callApi, serverError, showSuccess, toggleShowSuccess, message } =
    useApis(url, "POST");

  // Fetch initial data using GET request when id changes
  useEffect(() => {
    const fetch = async () => {
      callApi("", url, "GET");
    };
    fetch();
  }, [id]);

  // Handle form submission
  const handleSubmit = async (data) => {
    callApi(data);
  };

  // Handle post-reset actions
  useEffect(() => {
    message.postMessage === "Password reset successfully" &&
      setTimeout(() => {
        auth.logout();
        callApi(null, logoutUrl, "POST");
        navigate("/login");
      }, 3000);
  }, [message.postMessage, auth]);

  return (
    <>
      <LoggedOutLayout
        className={
          auth.title === "Access dashboard"
            ? "login-bg login-page-container "
            : ""
        }
        logoSrc={logo}
        title={auth.title}
      >
        {message.getMessage !== "This link has expired" ? (
          <>
            <DynamicForm
              fields={resetPasswordFields}
              onSubmit={handleSubmit}
              serverError={serverError}
              validationSchema={resetPasswordSchema}
            />
            {message.postMessage && (
              <>
                <Toaster
                  body="Password has been reset successfully!"
                  header="Success"
                  onClose={toggleShowSuccess}
                  position="top-end"
                  show={showSuccess}
                />
              </>
            )}
          </>
        ) : message.getMessage !== "Token is valid" ||
          message.getMessage == "This link has expired" ? (
          <div className="text-center">
            <Invalid message={serverError} />
            <Button
              className={"mt-4"}
              href="/login"
              label={"Back to Login"}
              variant={"danger"}
            />
          </div>
        ) : null}
      </LoggedOutLayout>
    </>
  );
}

export default ResetPassword;
