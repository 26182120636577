import React, { useState, useEffect, useRef } from "react";
import { Form, InputGroup } from "react-bootstrap";
import { Eye, EyeSlash, InfoCircleFill } from "react-bootstrap-icons";
import PropTypes from "prop-types";
import Select from "react-select";
import { formatBarcode } from "../../utils/utils";
import DOMPurify from "dompurify";

/**
 * Reusable input element with various customization options
 * @param {*} props
 * @returns
 */
function FormInput(props) {
  const [password, setPassword] = useState(props.value);
  const [showPassword, setShowPassword] = useState(false);
  const [inputValue, setInputValue] = useState(props.value || "");
  const [changeValue, setChangeValue] = useState(false);
  const selectRef = useRef(null);
  const [initialLoad, setInitialLoad] = useState(true); // New state to manage initial load

  useEffect(() => {
    if (props.type === "password") {
      setPassword(props.value);
    }

    if (props.name === "test_type") {
      // Always set input value from props for test_type
      setInputValue(props.value || "");
    } else if (props.useLocalStorage && !initialLoad) {
      const storedValue = localStorage.getItem(props.name);
      if (storedValue) {
        setInputValue(storedValue);
      } else {
        setInputValue(props.value || "");
      }
    } else {
      setInputValue(props.value || "");
    }

    if (props.type === "select") {
      const fieldName = props.name;
      const fieldValue = selectRef.current?.value;
      if (props.useLocalStorage && !initialLoad) {
        localStorage.setItem(fieldName, fieldValue);
      }
    }
    setInitialLoad(false); // Set to false after the first render
  }, [props.value, props.type, props.name, props.useLocalStorage]);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleChange = (e) => {
    if (!props.readOnly && !props.disabled) {
      const newValue = e.target.value;
      setInputValue(newValue);

      if (props.name === "primary_barcode") {
        e.target.value = formatBarcode(newValue);
      }

      props.onChange(e);

      if (props.type === "password") {
        setPassword(newValue);
      }

      if (props.useLocalStorage) {
        localStorage.setItem(props.name, newValue);
      }
    }
  };

  const handleBlur = (e) => {
    const fieldName = props.name;
    const fieldValue = e.target.value;

    if (props.useLocalStorage) {
      localStorage.setItem(fieldName, fieldValue);
    }

    if (props.name === "primary_barcode") {
      const formattedBarcode = formatBarcode(fieldValue);
      setInputValue(formattedBarcode);

      props.onChange({
        ...e,
        target: { ...e.target, value: formattedBarcode },
      });
    }

    if (props.onBlur) {
      props.onBlur(e);
    }
  };

  const midPoint = Math.ceil(props.passHints?.length / 2);

  const handleSelectChange = (selectedOption) => {
    setChangeValue(true);
    if (!props.readOnly && !props.disabled) {
      props.onChange({
        target: {
          name: props.name,
          value: selectedOption ? selectedOption.value : "",
        },
      });
    }
    props.onSelectChange(selectedOption);
  };

  const handleBarcodeChange = (e) => {
    if (!props.readOnly && !props.disabled) {
      let value = e.target.value;
      if (props.name === "primary_barcode") {
        // Clean input and format barcode
        let cleanedValue = value.replace(/[^a-zA-Z0-9]/g, "");
        // Remove non-alphanumeric characters
        let formattedValue = formatBarcode(cleanedValue);

        // Extract the segments to enforce alphabetic input and uppercase for the first segment
        let [firstSegment, secondSegment, remainingSegment] =
          formattedValue.split("-");

        // Prevent non-alphabetic characters in the first and second segments
        if (
          (firstSegment && !/^[A-Z]*$/.test(firstSegment)) ||
          (secondSegment && !/^[a-zA-Z]*$/.test(secondSegment))
        ) {
          return; // Prevent updating if segments contain invalid characters
        }

        // Prevent non-numeric characters in the remaining segment
        if (remainingSegment && !/^\d*$/.test(remainingSegment)) {
          return; // Prevent updating if it contains non-numeric characters
        }

        setInputValue(formattedValue);
        props.onChange({
          ...e,
          target: { ...e.target, value: formattedValue },
        });

        // Store formatted value in localStorage
        localStorage.setItem(props.name, formattedValue);
      } else {
        setInputValue(value);
        props.onChange(e);
      }
    }
  };

  const DropdownIndicator = () => null;

  // Determine the correct value for react-select
  const findOptionByValue = (value) => {
    return props.options.find((option) => option.value === value) || null;
  };

  const newValue = changeValue
    ? findOptionByValue(props.value)
    : findOptionByValue(props.storeName?.[0]?.store_info);

  // Sanitize the help text content
  const sanitizedHelpTextAbove = DOMPurify.sanitize(props.helpTextAbove);
  const sanitizedHelpText = DOMPurify.sanitize(props.helpText);

  return (
    <>
      <Form.Group
        className={props.className}
        controlId={props.controlId}
        key={props.key}
      >
        {props.label && (
          <Form.Label className="labels">
            {props.required ? (
              <>
                <span className="asterisk">*</span>
                {props.label}
              </>
            ) : (
              props.label
            )}
          </Form.Label>
        )}

        {props.helpTextAbove && (
          <Form.Text id={props.aria_describedby} muted={props.muted}>
            <span
              className="help-text-above"
              dangerouslySetInnerHTML={{ __html: sanitizedHelpTextAbove }}
            />
          </Form.Text>
        )}
        {props.type !== "select" &&
          props.type !== "dropdown" &&
          props.type !== "password" &&
          props.type !== "selectValue" && (
            <div className="d-flex gap-3">
              <Form.Control
                aria-describedby={props.aria_describedby}
                as={props?.as || Form.Control}
                disabled={props.disabled}
                isInvalid={props.touched && !!props.error}
                maxLength={props.maxLength}
                name={props.name}
                onBlur={handleBlur}
                onChange={
                  props.name === "primary_barcode"
                    ? handleBarcodeChange
                    : handleChange
                }
                placeholder={props.placeholder}
                readOnly={props.readOnly}
                required={props.required}
                size={props.size}
                type={props.type}
                value={inputValue}
              />
              {props.image && <img alt="Barcode" src={props.image} />}
            </div>
          )}
        {props.type === "password" && (
          <InputGroup>
            <Form.Control
              aria-describedby={props.aria_describedby}
              as={props?.as || Form.Control}
              disabled={props.disabled}
              isInvalid={props.touched && !!props.error}
              name={props.name}
              onBlur={handleBlur}
              onChange={handleChange}
              placeholder={props.placeholder}
              readOnly={props.readOnly}
              required={props.required}
              size={props.size}
              type={showPassword ? "text" : "password"}
              value={password}
            />

            <InputGroup.Text
              className="password-input"
              onClick={togglePasswordVisibility}
              style={{ cursor: "pointer" }}
            >
              <span>{showPassword ? <Eye /> : <EyeSlash />}</span>
            </InputGroup.Text>
          </InputGroup>
        )}
        {(props.type === "select" || props.type === "selectValue") && (
          <Form.Select
            aria-label="Default select example"
            isInvalid={props.touched && !!props.error}
            onChange={(e) =>
              handleSelectChange(
                props.options.find((option) => option.value === e.target.value),
              )
            }
            placeholder={props.placeholder}
            ref={selectRef}
            value={props.value}
            {...props}
          >
            <option value="">{props.placeholder}</option>
            {props.options.map((option) => (
              <option key={option.value} value={option.value}>
                {props.type === "select" ? option : option.label}
              </option>
            ))}
          </Form.Select>
        )}
        {props.type === "dropdown" && (
          <Select
            className="location-dropdown"
            components={{ DropdownIndicator }}
            isClearable
            isSearchable
            onBlur={handleBlur}
            onChange={handleSelectChange}
            options={props.options}
            value={newValue} // newValue should be in the format { value: '1000', label: 'Piccadilly Circus' }
          />
        )}
        {props.passHints && (
          <div className="d-flex">
            <ul>
              {props.passHints.slice(0, midPoint).map((passHint, index) => (
                <li
                  className={props.error === passHint ? "highlights" : ""}
                  key={index}
                >
                  {passHint}
                </li>
              ))}
            </ul>
            <ul>
              {props.passHints.slice(midPoint).map((passHint, index) => (
                <li
                  className={props.error === passHint ? "highlights" : ""}
                  key={index}
                >
                  {passHint}
                </li>
              ))}
            </ul>
          </div>
        )}
        <Form.Text id={props.aria_describedby} muted={props.muted}>
          <span
            className={props.helptextClass}
            dangerouslySetInnerHTML={{ __html: sanitizedHelpText }}
          />
        </Form.Text>
        <Form.Control.Feedback type="invalid">
          {props.error && (
            <>
              <InfoCircleFill /> {props.error}
            </>
          )}
        </Form.Control.Feedback>
        {props.links && (
          <span className="d-flex justify-content-end">{props.links}</span>
        )}
      </Form.Group>
    </>
  );
}

FormInput.propTypes = {
  label: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  as: PropTypes.string,
  required: PropTypes.bool,
  placeholder: PropTypes.string,
  size: PropTypes.oneOf(["sm", "text", "lg"]),
  readOnly: PropTypes.bool,
  disabled: PropTypes.bool,
  aria_describedby: PropTypes.string,
  helpText: PropTypes.string,
  helpTextAbove: PropTypes.string,
  controlId: PropTypes.string,
  className: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func,
  value: PropTypes.any.isRequired,
  touched: PropTypes.bool,
  error: PropTypes.string,
  helptextClass: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
    }),
  ).isRequired,
  muted: PropTypes.bool,
  image: PropTypes.string,
  passHints: PropTypes.arrayOf(PropTypes.string),
  links: PropTypes.node,
  handleBarcodeChange: PropTypes.func,
};

FormInput.defaultProps = {
  label: "Email",
  type: "email",
  required: true,
  placeholder: "Enter your email address",
  size: "text",
  readOnly: false,
  disabled: false,
  options: [],
  helpTextAbove: "",
  controlId: "",
  className: "",
  touched: false,
  error: "",
  muted: false,
};

export default FormInput;
