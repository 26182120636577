import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Button as BootstrapButton, Dropdown, Image } from "react-bootstrap";

/* Define a function to dynamically import icons */
const importIcon = (iconName) =>
  import(`react-bootstrap-icons/dist/icons/${iconName}.js`);

/**
 * Primary UI component for user interaction
 */
export const Button = ({
  variant,
  className: userClassName,
  size,
  label,
  onClick,
  icon,
  target,
  isDisabled,
  iconOrder,
  svg,
  type,
  items,
  username,
  initials,
  dropdown,
  hover,
  ...props
}) => {
  const [IconComponent, setIconComponent] = useState(null);
  const [validComponent, setValidComponent] = useState(null);
  const direction = iconOrder === "On left" ? "rtl" : "ltr";

  /* Load icon dynamically when icon prop changes */
  useEffect(() => {
    if (icon) {
      importIcon(icon)
        .then((module) => {
          setIconComponent(module.default);
          setValidComponent(null);
        })
        .catch(() => {
          setIconComponent(null);
          setValidComponent("Please enter a valid Icon name");
        });
    } else {
      setIconComponent(null);
    }
  }, [icon]);

  const variantClassName = `btn btn--${variant}`;
  const sizeClassName = `btn--${size}`;
  const rel = target ? "noopener noreferrer" : null;

  /* Concatenate the user-provided className with other className names */
  const combinedClassName = `${variantClassName} ${sizeClassName} ${userClassName} ${hover ? "" : "no-hover"}`;

  return (
    <>
      {type !== "dropdown" ? (
        <BootstrapButton
          className={combinedClassName}
          disabled={isDisabled}
          onClick={onClick}
          rel={rel}
          style={{ outline: "none", direction }}
          svg={svg}
          target={target ? "_blank" : null}
          type={type}
          variant={variant}
          {...props}
        >
          <div className="icon">{svg && <img src={svg} />}</div>
          <span>{label}</span>
          {IconComponent && <IconComponent className="icon-margin" />}
        </BootstrapButton>
      ) : (
        <Dropdown
          className={
            !dropdown ? "profile-color" : `profile-color notification-dropdown`
          }
        >
          <Dropdown.Toggle
            className={combinedClassName}
            id="dropdown-basic"
            initials={initials}
            items={items}
            svg={svg}
            username={username}
            variant={variant}
          >
            {svg && <Image alt="svg" fluid src={svg} />}

            <span>{label}</span>
          </Dropdown.Toggle>

          <Dropdown.Menu className="">
            {username && ( // Render only if username exists
              <Dropdown.Item href="#">
                <span className="profile-circle">{initials}</span>
                {username}
              </Dropdown.Item>
            )}
            {items &&
              Array.isArray(items) &&
              items.map((item, index) => (
                <Dropdown.Item href={item.href} key={index}>
                  {item.label}
                </Dropdown.Item>
              ))}
          </Dropdown.Menu>
        </Dropdown>
      )}
      {validComponent && (
        <div className="icon-validation">{validComponent}</div>
      )}
    </>
  );
};

/* Default props for buttons */
Button.defaultProps = {
  variant: "primary",
  size: "medium",
  isDisabled: false,
  iconOrder: "On right",
  hover: true, // Default to enable hover
};

/* PropTypes for buttons */
Button.propTypes = {
  variant: PropTypes.oneOf([
    "primary",
    "secondary",
    "danger",
    "gray",
    "outline",
    "olive",
    "offwhite",
    "white",
    "light-gray",
    "grey-lilac",
    "yellow",
    "powder-pink",
    "mint",
    "dark-green",
    "raspberry",
  ]),
  size: PropTypes.oneOf(["small", "medium", "large"]),
  label: PropTypes.string,
  icon: PropTypes.string,
  svg: PropTypes.string,
  target: PropTypes.bool,
  onClick: PropTypes.func,
  isDisabled: PropTypes.bool,
  className: PropTypes.string,
  iconOrder: PropTypes.oneOf(["On left", "On right"]),
  hover: PropTypes.bool,
};
