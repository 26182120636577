import React, { useEffect, useState } from "react";
import DynamicForm from "../stories/Forms/DynamicForm";
import { changePasswordSchema } from "../stories/Forms/validationSchema";
import { Container, Col, Row } from "react-bootstrap";
import { changePasswordFields, links } from "../stories/Forms/fields";
import useApis from "../services/useAPI";
import Toaster from "../stories/Toasts/Toaster";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../components/AuthContext/AuthContext";
import { logoutUrl, changePasswordUrl } from "../utils/constants";
import LoggedInLayout from "../stories/LoggedInScreens/LoggedInLayout";
import logo from "../assets/images/mhc.png";
import img from "../assets/images/notification.svg";

// Component for handling change password functionality
function ChangePassword() {
  const navigate = useNavigate();
  const auth = useAuth();
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);

  // API call hook with various states
  const {
    callApi,
    serverError,
    isLoading,
    showSuccess,
    toggleShowSuccess,
    toggleShowError,
    showError,
    tokenError,
    message,
  } = useApis();

  // Function to handle form submission
  const handleSubmit = async (data) => {
    // Call API to change password
    await callApi(data, changePasswordUrl, "PUT", {
      Authorization: `Bearer ${auth.accessToken}`,
      "Content-Type": "application/json",
    });
  };

  // Effect to handle post password change actions
  useEffect(() => {
    if (message.postMessage && showSuccess) {
      setShowSuccessMessage(true);
      setTimeout(() => {
        // Logout user and redirect to login page
        auth.logout();
        callApi(null, logoutUrl, "POST");
        setTimeout(() => {
          navigate("/login");
        }, 1000);
      }, 3000);
    }
  }, [message.postMessage, showSuccess, navigate, auth, callApi]);

  return (
    <LoggedInLayout
      buttonLabel={"Logout"}
      img={img}
      isActivation={false}
      links={links}
      logo={logo}
      pageTitle={auth.title}
      userInitials={auth.userInitials}
    >
      <Container>
        <Row>
          <Col md={6}>
            {/* Form component for changing password */}
            <DynamicForm
              fields={changePasswordFields}
              isLoading={isLoading}
              onSubmit={handleSubmit}
              validationSchema={changePasswordSchema}
            />
            {/* Success message toaster */}
            {showSuccessMessage && (
              <Toaster
                body="Password changed successfully!"
                header="Success"
                onClose={toggleShowSuccess}
                position="top-end"
                show={showSuccess}
              />
            )}
            {/* Error message toaster */}
            {(serverError || tokenError) && (
              <Toaster
                body={tokenError ? tokenError : serverError}
                header="Error"
                onClose={toggleShowError}
                position="top-end"
                show={tokenError ? tokenError : showError}
              />
            )}
          </Col>
        </Row>
      </Container>
    </LoggedInLayout>
  );
}

export default ChangePassword;
